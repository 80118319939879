import React, { useState, useRef, useCallback } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { styles } from './styles'
import IOSSwitch from '../../../IOSSwitch'
import { checkIfSubmittable, validation } from '../../../../../utils/validation'
import { Link, navigate } from 'gatsby'
import { ENDPOINTS, ROUTES, EXTENSION_ID, COLORS } from '../../../../constants'
import { Auth } from '@aws-amplify/auth'
import axios from '../../../../axios'
import moment from 'moment'
import { useAppState } from '../../../../appState'
import emailIcon from '../../../../assets/images/mail.svg'
import { sendExtensionMessage } from '../../../../../utils/extensionHelper'
import { Box, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { useLocation } from '@reach/router'
import { TrackingCase } from '../../../../../utils/trackingCases'
import { setItemToLocalStorage } from '../../../../../utils/setLocalStorage'
import { callWithRetry } from '../../../../../utils/retry'
import { tracker } from '../../../../systemLogs'
import ReCAPTCHA from 'react-google-recaptcha'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import chirpyestTextLogo from '../../../../assets/images/chirpyest-text-logo.svg'
import chrLogo from '../../../../assets/images/chirpyest.svg'
import SignInInput from '../../signInInput'
import SignInButton from '../../signInButton'
import CHRHeaderDialog from '../../../headerDialog'
import burgerMenuIcon from '../../../../assets/images/burger-menu.svg'

declare const window: any

interface IJoinWithEmailProps {
  nextStep: () => void
}

const JoinWithEmailApp = ({ nextStep }: IJoinWithEmailProps) => {
  const classes = styles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isTermsChecked, setIsTermsChecked] = useState(true)
  const [isNewsletterChecked, setIsNewsletterChecked] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [isOverlayOpen, setOverlayStatus] = useState(false)

  const [formErrors, setFormErrors] = useState({
    emailValidationError: false,
    emailErrorText: '',
    passwordValidationError: false,
    passwordErrorText: '',
    requestError: false,
    requestErrorText: '',
  })
  // @ts-ignore
  const [state, dispatch] = useAppState()
  const [captchaVerified, setCaptchaVerified] = useState(false)
  const [tryCaptcha, setTryCaptcha] = useState(0)
  const { pathname } = useLocation()
  const timer = useRef(null)
  const reRef = useRef<ReCAPTCHA>(null)

  const referralId = pathname?.split('/')[2]?.split('#')[0] || ''
  const eye = <VisibilityOutlinedIcon className={classes.eyeIcon} />
  const eyeSlash = <VisibilityOffOutlinedIcon className={classes.eyeIcon} />
  const urlParams = new URLSearchParams(window.location.search)
  const redirectParam = urlParams.get('redirect')

  const openMenuOverlay = useCallback(() => {
    setOverlayStatus(true)
  }, [])

  const closeMenuOverlay = useCallback(() => {
    setOverlayStatus(false)
  }, [])

  const handleSignUp = async () => {
    setIsSubmitting(true)
    setFormErrors({
      emailValidationError: false,
      emailErrorText: '',
      passwordValidationError: false,
      passwordErrorText: '',
      requestError: false,
      requestErrorText: '',
    })
    try {
      const isFormValid = await validation({ email, password })
      if (isTermsChecked && isFormValid && captchaVerified) {
        let userData = {
          email,
          password,
          termsCondsAccepted: isTermsChecked,
          newsletterSubscription: isNewsletterChecked,
          extensionDownloaded: true,
        }
        if (referralId) {
          Object.assign(userData, { referral: referralId })
        } else {
          const referralIdFallBackJSON = window.localStorage.getItem(
            'referralId'
          )
          const referralIdFallBack = JSON.parse(referralIdFallBackJSON)
          if (referralIdFallBack) {
            const { id, time } = referralIdFallBack
            const referralIdAge = moment().diff(moment(time), 'days')
            if (referralIdAge <= 3) {
              Object.assign(userData, { referral: id })
            }
          }
        }
        tracker.track(TrackingCase.UserTracking, `POST ${ENDPOINTS.signUp}`, {
          location: 'MainJoinModal',
          email,
        })
        try {
          const response = await axios.post(ENDPOINTS.signUp, userData)
          console.log(response)
          const awsRetryConfig = {
            timeout: 5 * 1000,
            maxRetries: 2,
            waitBetweenTries: 2 * 1000,
          }
          const user = await callWithRetry(
            () => Auth.signIn(email, password),
            awsRetryConfig
          )

          setItemToLocalStorage('signUp', 'firstVisit')
          setItemToLocalStorage('firstMove', 'true')

          if (user) {
            const currentSession = await callWithRetry(
              () => Auth.currentSession(),
              awsRetryConfig
            )

            if (!currentSession) {
              navigate(
                `${ROUTES.home}?signInFromExtension=signInFromExtension&ios=true#sign-in`
              )
            } else {
              const {
                data: { data },
              } = await axios.get(
                ENDPOINTS.user.replace(':id', user.attributes['custom:user_id'])
              )
              const userProfile = {
                email: data.email,
                userName: data.username,
                fullName: data.name,
                imageKey: data.image,
                imageUrl: '' as Object | string,
                isPro: data.isPro,
                isCreator: data.isCreator,
                isReferralBonus: data.isReferralBonus,
                userDoublePayment: data.userDoublePayment,
                financialData: data.financialData || {
                  pending: '0',
                  lastClosedOut: '0',
                  receivableMilestone: '0',
                },
                loginProvider: data.loginProvider,
              }

              if (data.image && currentSession?.identityId) {
                const url = `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${data.image}`
                userProfile.imageUrl = url
              }
              sendExtensionMessage({ session: currentSession })
              if (window.chrome?.runtime?.sendMessage) {
                window.chrome.runtime.sendMessage(
                  EXTENSION_ID,
                  { session: currentSession },
                  (_response: any) => {}
                )
              }

              dispatch({ type: 'SIGN_IN', auth: user, userProfile })
              nextStep()
            }
          }
        } catch (error) {
          // @ts-ignore
          setFormErrors({
            requestError: true,
            requestErrorText:
              error?.response?.data?.message || 'There is some error',
          })
          window.Sentry.captureMessage('Signup Failed : ' + userData.email)
          window.Sentry.captureException(
            error?.response?.data?.message || error
          )
        }
      }
      if (captchaVerified == false && tryCaptcha == 0) {
        // @ts-ignore
        setFormErrors({
          requestError: true,
          requestErrorText: 'Please verify captcha!',
        })
      }
      if (captchaVerified == false && tryCaptcha > 0) {
        // @ts-ignore
        setFormErrors({
          requestError: true,
          requestErrorText: 'Captcha token not verified from server!',
        })
      }
    } catch (error) {
      const responseError = error?.response

      if (error?.errors) {
        if (error?.inner[0]?.params.path === 'email') {
          // @ts-ignore
          setFormErrors({
            emailValidationError: true,
            emailErrorText: error?.inner[0]?.message,
            passwordValidationError:
              error?.inner[1]?.params?.path === 'password',
            passwordErrorText: error?.inner[1]?.message,
          })
        } else {
          // @ts-ignore
          setFormErrors({
            passwordErrorText: error?.inner[0]?.message,
            passwordValidationError:
              error?.inner[0]?.params?.path === 'password',
          })
        }
      } else {
        let errMsg = 'error creating a new account'
        if (responseError) {
          const loginProvider =
            responseError.data.loginProvider?.toLowerCase() || ''
          const socialProvider = loginProvider.includes('google')
            ? 'Google'
            : 'Apple'
          const isConflict = responseError.status === 409
          const isSocialProvider =
            loginProvider.includes('google') || loginProvider.includes('apple')

          if (isConflict && isSocialProvider) {
            errMsg = `You have already singed in with this email with ${socialProvider}`
            // @ts-ignore
            timer.current = setTimeout(() => {
              navigate(
                `${ROUTES.home}?signInFromExtension=signInFromExtension&ios=true#sign-in`
              )
            }, 3000)
          } else if (isConflict) {
            return handleAutoSignIn(email, password)
          }
        }
        // @ts-ignore
        setFormErrors({
          requestError: true,
          requestErrorText: errMsg,
        })
      }
    }

    setIsSubmitting(false)
  }

  // TODO: Hashem --> fix the onChange function
  const onChange = async (token: any) => {
    if (token === null) {
      reRef?.current?.reset()
      setCaptchaVerified(false)
    } else {
      setCaptchaVerified(true)
      // @ts-ignore
      setFormErrors({
        requestError: false,
        requestErrorText: '',
      })
    }

    // if (token === null) {
    //   reRef?.current?.reset()
    //   setCaptchaVerified(false)
    // } else {
    //   setTryCaptcha((preState: number) => (preState += 1))
    // @ts-ignore

    //   setFormErrors({
    //     requestError: false,
    //     requestErrorText: '',
    //   })
    //   const response = await axios.post(ENDPOINTS.verifyCaptcha, { token })
    //   if (response?.data?.data?.success) {
    //     setCaptchaVerified(true)
    //   } else {
    //     reRef?.current?.reset()
    //     // @ts-ignore

    //     setFormErrors({
    //       requestError: true,
    //       requestErrorText: 'Captcha token not verified from server!',
    //     })
    //   }
    // }
  }

  const handleAutoSignIn = async (email: string, password: string) => {
    setIsSubmitting(true)
    try {
      const user = await Auth.signIn(email, password)

      if (!user.signInUserSession.accessToken) {
        throw new Error('Access is not authorized.')
      }

      const {
        data: { data },
      } = await axios.get(
        ENDPOINTS.user.replace(':id', user.attributes['custom:user_id'])
      )
      const currentUserInfo = await Auth.currentCredentials()
      const session = await Auth.currentSession()
      const userProfile = {
        email: data.email,
        userName: data.username,
        fullName: data.name,
        imageKey: data.image,
        imageUrl: '' as Object | string,
        isPro: data.isPro,
        isCreator: data.isCreator,
        isReferralBonus: data.isReferralBonus,
        userDoublePayment: data.userDoublePayment,
        financialData: data.financialData || {
          pending: '0',
          lastClosedOut: '0',
          receivableMilestone: '0',
        },
        loginProvider: data.loginProvider,
      }

      if (data.image && currentUserInfo?.identityId) {
        const url = `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${data.image}`
        userProfile.imageUrl = url
      }

      sendExtensionMessage({ session })

      if (window.chrome?.runtime?.sendMessage) {
        window.chrome.runtime.sendMessage(
          EXTENSION_ID,
          { session },
          (_response: any) => {}
        )
      }

      dispatch({ type: 'SIGN_IN', auth: user, userProfile })

      const urlParams = new URLSearchParams(window.location.search)
      const signInFromExtension = urlParams.get('signInFromExtension')

      navigate(
        `${ROUTES.member}${
          signInFromExtension ? '?signInFromExtension=signInFromExtension' : ''
        }`
      )
    } catch (err) {
      // @ts-ignore

      setFormErrors({
        requestError: true,
        requestErrorText: 'An account with this email already exists',
      })
    }
    setIsSubmitting(false)
  }

  const buttonProps = [
    {
      onClick: handleSignUp,
      label: 'join with email',
      icon: emailIcon,
      customButtonStyle: { backgroundColor: COLORS.lightTeal },
      customIconStyle: { width: '16px', height: '13.091px' },
      id: 1,
    },
  ]

  return (
    <Dialog fullScreen={true} open={true} aria-labelledby="joinWithEmail">
      {/* Dialog Header */}
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={openMenuOverlay}
          >
            <img
              src={burgerMenuIcon}
              className={classes.menuIcon}
              alt={'menu'}
            />
          </IconButton>

          <img
            src={chirpyestTextLogo}
            className={classes.homeHeaderLogo}
            alt="chirpyest-text-logo"
          />
          <img src={chrLogo} className={classes.logo} />
        </Box>
      </DialogTitle>
      <Box component="section" className={classes.dialogContent}>
        {/* Title */}
        <Typography variant="h2" className={classes.title}>
          join chirpyest
        </Typography>

        {/* User credentials */}
        <Box className={classes.credentials}>
          <Box className={classes.credentialBox}>
            <SignInInput
              value={email}
              onChange={(e: {
                target: { value: React.SetStateAction<string> }
              }) => {
                setEmail(e.target.value)
              }}
              placeholder="email address"
              type="email"
              error={!!formErrors.emailValidationError}
              required
              onKeyPress={(event: any) =>
                !isSubmitting && checkIfSubmittable(event, handleSignUp)
              }
            />
          </Box>
          {formErrors.emailValidationError && (
            <p className={classes.errorMessage}>{formErrors.emailErrorText}</p>
          )}
          <Box className={classes.credentialBox}>
            <Box className={classes.passwordField}>
              <SignInInput
                value={password}
                onChange={(e: {
                  target: { value: React.SetStateAction<string> }
                }) => {
                  setPassword(e.target.value)
                }}
                placeholder="choose password"
                type={passwordShown ? 'text' : 'password'}
                error={!!formErrors.passwordValidationError}
                onKeyPress={(event: any) =>
                  !isSubmitting && checkIfSubmittable(event, handleSignUp)
                }
                autoComplete="new-password"
              />
              <Box
                className={classes.passVisibility}
                onClick={() => setPasswordShown(!passwordShown)}
              >
                {passwordShown ? eye : eyeSlash}
              </Box>
            </Box>
          </Box>
          {formErrors.passwordValidationError && (
            <p className={classes.errorMessage}>
              {formErrors.passwordErrorText}
            </p>
          )}
          <p className={classes.passwordGuidelines}>
            upper & lower case • 8+ characters • 1+ number
          </p>

          <Box className={classes.recaptchaWrapper}>
            <Box className={classes.recaptchaContainer}>
              <ReCAPTCHA
                sitekey={`${process.env.GATSBY_RECAPTCHA_SECRET_KEY}`} //"6LexZ3olAAAAAG_dB8FE1zj9wYuq8NmYWI3KqJss" //{`${process.env.GATSBY_RECAPTCHA_SECRET_KEY}`}
                onChange={onChange}
                ref={reRef}
              />
            </Box>
          </Box>
        </Box>
        {formErrors.requestError && (
          <p className={classes.requestError}>{formErrors.requestErrorText}</p>
        )}
        {/* Sign in Buttons */}
        <Box>
          {buttonProps.map((button: any) => (
            <SignInButton
              key={button.id}
              onClick={button.onClick}
              label={button.label}
              icon={button.icon}
              isSubmitting={isSubmitting}
              disabled={isSubmitting}
              customIconStyle={button.customIconStyle}
              customButtonStyle={button.customButtonStyle}
            />
          ))}
          {/* Switches */}
          <Box className={classes.switches}>
            {!isTermsChecked && (
              <p className={classes.errorMessage}>
                terms and conditions must be checked
              </p>
            )}
            <Box className={classes.termsWrapper}>
              <IOSSwitch
                checked={isTermsChecked}
                onChange={() => setIsTermsChecked(!isTermsChecked)}
                name="terms"
              />
              <p>
                accept{' '}
                <span className={classes.link}>
                  <a
                    href={ROUTES.terms}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#000', textDecoration: 'none' }}
                  >
                    terms & privacy
                  </a>
                </span>
              </p>
            </Box>
            <Box className={classes.termsWrapper}>
              <IOSSwitch
                checked={isNewsletterChecked}
                onChange={() => setIsNewsletterChecked(!isNewsletterChecked)}
                name="newsletters"
              />
              <p>subscribe to newsletter</p>
            </Box>
          </Box>
        </Box>

        {/* sign in message */}
        <Typography variant="subtitle1" className={classes.text}>
          already a member?{' '}
          <Link
            rel="preconnect"
            to={`${ROUTES.home}?ios=true&redirect=${redirectParam}#sign-in`}
            className={classes.link}
          >
            Log in
          </Link>
        </Typography>
      </Box>
      <CHRHeaderDialog
        handleClose={closeMenuOverlay}
        openStatus={isOverlayOpen}
        isFromIos
      />
    </Dialog>
  )
}

export default JoinWithEmailApp
