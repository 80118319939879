import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  greeting: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    fontSize: '16px',
  },
  greetingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: '20px',
  },
  signOut: {
    display: 'flex',
    padding: '10px 18px',
    alignItems: 'center',
    gap: '10px',
    border: '1px solid black',
  },
  signOutLink: {
    textDecoration: 'none',
    color: COLORS.black,
    textAlign: 'center',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '13px',
  },
  infoWrapper: {
    display: 'flex',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  profileImg: {
    width: '80px',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '50%',
  },
  userName: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 600,
    fontSize: '17px',
    margin: 0,
  },
  text: {
    fontFamily: FONTS.Graphik.GraphikLight,
    fontWeight: 300,
    fontSize: '10px',
    margin: 0,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px',
    justifyContent: 'center',
    paddingBottom: '8px',
  },
  linksWrapper: {
    borderTop: '1px solid black',
    margin: '50px 0',
  },
  link: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'black',
    borderBottom: '1px solid black',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  linkText: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    fontSize: '16px',
    marginTop: '18px',
    marginBottom: '18px',
  },
  arrow: {
    width: '20px',
    height: '20px',
  },
  shareTitle: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    fontSize: '16px',
  },
  shareText: {
    fontFamily: FONTS.Graphik.GraphikLight,
    fontWeight: 300,
    fontSize: '12px',
    margin: '0 10px 0',
  },
  boldText: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
  },
  shareWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  button: {
    backgroundColor: `${COLORS.lightTeal} !important`,
    borderColor: COLORS.lightTeal,
    border: '1px solid #000000 !important',
    maxHeight: '40px',
    maxWidth: '93px',
    margin: 0,
  },
  buttonText: {
    color: '#000000 !important',
    fontSize: '13px',
  },
  underline: {
    textDecoration: 'underline',
  },
  socialMediaList: {
    display: 'flex',
    alignItems: 'center',
    listStyleType: 'none',
    paddingLeft: 0,
  },
  socialListItem: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  socialIcon: {
    display: 'block',
    width: 15,
    height: 15,
    fontSize: 15,
    color: 'black',
    ['& > img']: {
      maxWidth: '100%',
    },
  },
  socialMediaIcon: {
    display: 'block',
    width: 15,
    height: 15,
    fontSize: 20,
  },
  footer: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '1px solid black',
    paddingBottom: '20px',
    marginBottom: '110px',
    maxWidth: '508px',
  },
}))
