import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS, COLORS } from '../../../constants'

export const styles = makeStyles(theme => ({
  dialogContainer: {
    maxWidth: '508px',
    margin: '0 auto',
  },
  title: {
    fontSize: '32px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    margin: 0,
    marginTop: '50px',
    marginBottom: '20px',
  },
  text: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontStyle: 'normal',
    lineHeight: '21px',
    letterSpacing: '0.156px',
    textTransform: 'lowercase',
    marginBottom: 0,
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: '300px',
    },
  },
  loginButton: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: COLORS.black,
  },
  dialogTitle: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    color: theme.palette.common.black,
  },
  toolbar: {
    position: 'relative',
    padding: '20px 15px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  homeHeaderLogo: {
    width: '136px',
    height: '35px',
  },
  logo: {
    width: '26px',
    height: '28px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0 40px',
    justifyContent: 'center',
  },
  menuIcon: {
    width: '24px',
    height: '11.2px',
  },
  topBrandsContainer: {
    marginLeft: '20px',
    marginRight: '20px',
  },
  topBrandsTitle: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    marginTop: 0,
    marginBottom: '5px',
  },
  shopItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  brandName: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '12px',
    fontWeight: 500,
    margin: 0,
  },
  cashBack: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '10px',
    fontWeight: 400,
    color: '#175972',
    margin: 0,
  },
  shopLink: {
    padding: '10px 30px',
    border: '1px solid #000000 !important',
    textDecoration: 'none',
    color: '#000',
    fontSize: '13px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
  },
  topBrandImage: {
    maxWidth: '50px',
    height: '50px',
    marginRight: '20px',
    alignItems: 'center',
  },
  brandCard: {
    display: 'flex',
  },
}))
