import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(() => ({
  screenContainer: {
    paddingBottom: '60px',
  },
  tabContent: {
    maxWidth: '508px',
    margin: '0 auto',
  },
  bottomNav: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: '66px',
  },
  tabLabel: {
    fontSize: '10px !important',
  },
  userImage: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
  },
  header: {
    padding: '20px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #000000',
  },
  headerText: {
    fontSize: '18px',
  },
  logo: {
    width: '26px',
    height: '28px',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  homeHeaderLogo: {
    width: '136px',
    height: '35px',
  },
  loadingScreenWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  textLogo: {
    width: '136px',
    height: '35px',
  },
  // Define the keyframe animation for flashing
  flashAnimation: {
    animation: '$flash 1.5s infinite', // Apply the animation
  },

  // Keyframes for the flash animation
  '@keyframes flash': {
    '0%, 100%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
    },
  },
}))
