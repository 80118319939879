import React, { useCallback, useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import emailIcon from '../../../../assets/images/mail.svg'
import googleIcon from '../../../../assets/images/google-icon.svg'
import appleIcon from '../../../../assets/images/apple.svg'
import burgerMenuIcon from '../../../../assets/images/burger-menu.svg'

import SignInButton from '../../signInButton'
import { COLORS, ROUTES } from '../../../../constants'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import Dialog from '@material-ui/core/Dialog'
import { styles } from './styles'
import chrLogo from '../../../../assets/images/chirpyest.svg'
import chirpyestTextLogo from '../../../../assets/images/chirpyest-text-logo.svg'
import { Box, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import CHRHeaderDialog from '../../../headerDialog'

// Types Declarations
interface IMainJoinProps {
  nextStep: () => void
}

const MainJoinApp = ({ nextStep }: IMainJoinProps) => {
  const classes = styles()
  const [isOverlayOpen, setOverlayStatus] = useState(false)
  const urlParams = new URLSearchParams(window.location.search)
  const redirectParam = urlParams.get('redirect')

  const buttonProps = [
    {
      onClick: () =>
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Google,
        }),
      label: 'join with google',
      icon: googleIcon,
      customIconStyle: { width: '21.059px', height: '21.586px' },
      id: 1,
    },
    {
      onClick: () =>
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Apple,
        }),
      label: 'join with apple',
      icon: appleIcon,
      customIconStyle: { width: '21px', height: '24.419px' },
      id: 2,
    },
    {
      onClick: nextStep,
      label: 'join with email',
      icon: emailIcon,
      customButtonStyle: { backgroundColor: COLORS.lightTeal },
      customIconStyle: { width: '16px', height: '13.091px' },
      id: 3,
    },
  ]
  const openMenuOverlay = useCallback(() => {
    setOverlayStatus(true)
  }, [])

  const closeMenuOverlay = useCallback(() => {
    setOverlayStatus(false)
  }, [])

  return (
    <Dialog
      fullScreen={true}
      open={true}
      aria-labelledby="responsive-dialog-title"
    >
      {/* Dialog Header */}
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={openMenuOverlay}
          >
            <img
              src={burgerMenuIcon}
              className={classes.menuIcon}
              alt={'menu'}
            />
          </IconButton>

          <img
            src={chirpyestTextLogo}
            className={classes.homeHeaderLogo}
            alt="chirpyest-text-logo"
          />
          <img src={chrLogo} className={classes.logo} />
        </Box>
      </DialogTitle>

      <Box component="section" className={classes.dialogContent} mb={7.5}>
        {/* Title & Description */}
        <Typography variant="h2" className={classes.title}>
          join chirpyest
        </Typography>

        <Typography variant="subtitle1" className={classes.text}>
          get cash back from 1000s of retailers and top brands. join to shop,
          share and earn $$!
        </Typography>

        {/* Sign in Buttons */}
        <Box mt={1.25}>
          {buttonProps.map((button: any) => (
            <SignInButton
              key={button.id}
              onClick={button.onClick}
              label={button.label}
              icon={button.icon}
              customIconStyle={button.customIconStyle}
              customButtonStyle={button.customButtonStyle}
            />
          ))}
        </Box>

        <Typography variant="subtitle1" className={classes.text}>
          already a member?{' '}
          <Link
            rel="preconnect"
            to={`${ROUTES.home}?ios=true&redirect=${redirectParam}#sign-in`}
            className={classes.loginButton}
          >
            Log in
          </Link>
        </Typography>
      </Box>
      <CHRHeaderDialog
        handleClose={closeMenuOverlay}
        openStatus={isOverlayOpen}
        isFromIos
      />
    </Dialog>
  )
}

export default MainJoinApp
