import React, { useState, useEffect } from 'react'
import {
  Typography,
  Grid,
  Box,
  Card as MUIcard,
  CardContent,
} from '@material-ui/core'
import axios from '../../../axios'
import { ENDPOINTS } from '../../../constants'

import CHRImage from '../../trendingCard/CHRImage'

import { styles } from './styles'
import CHRSpinner from '../../spinner'

const ShareLinkApp = () => {
  const classes = styles()

  const [boardInfo, setBoardInfo] = useState({
    isLoading: true,
    boardItems: [],
  })
  const [username, setUsername] = useState<string>(
    window.localStorage.getItem('storedUsername') || ''
  )
  // const [username, setUsername] = useState<string>('hashem7')

  useEffect(() => {
    const targetNode = document.getElementById('userNameValue')
    const injectedUsername = targetNode?.textContent || ''
    if (injectedUsername) {
      window.localStorage.setItem('storedUsername', injectedUsername)
      setUsername(injectedUsername)
      return
    }
  }, [])

  useEffect(() => {
    const getBoardItems = async () => {
      try {
        setBoardInfo({
          isLoading: true,
          boardItems: [],
        })
        const endpoint = ENDPOINTS.shoppingBoardNonUser.replace(':id', username)
        const {
          data: {
            data: { shoppingBoardItems },
          },
        } = await axios(endpoint)
        setBoardInfo({
          isLoading: false,
          boardItems: shoppingBoardItems.sort(
            (a: any, b: any) => a.position - b.position
          ),
        })
      } catch (e) {
        setBoardInfo({
          isLoading: false,
          boardItems: [],
        })
      }
    }
    if (username) {
      getBoardItems()
    } else {
      setBoardInfo({
        isLoading: false,
        boardItems: [],
      })
    }
  }, [username])

  if (boardInfo?.isLoading) return <CHRSpinner />

  return (
    <div className={classes.container}>
      <div>
        <h1 className={classes.title}>create share link</h1>
        <p className={classes.text}>
          paste a link from one of our affiliated brand sites to add it to your
          shopping board or convert it to a chirpyest share link and earn cash
          back if people shop through it!
        </p>
        <a
          className={classes.viewBtn}
          href={`${process.env.GATSBY_SITE_URL}/user/share-with-friends?ios=true&redirect=/user/share-with-friends`}
          target="_blank"
        >
          tap to create share link
        </a>
      </div>
      <h2 className={classes.title}>your shopping board</h2>

      <>
        <p className={classes.text}>
          a shopping board is your own curated collection of products that you
          can share with others - like your own shop or storefront. if someone
          shops your board. you earn money.
          <br /> <br />
          Use our extension to add items to your board or click below to start
          adding items to your board and to share it.
        </p>
        <a
          className={classes.viewBtn}
          href={
            username
              ? `${process.env.GATSBY_SITE_URL}/s/${username}`
              : `${process.env.GATSBY_SITE_URL}/?ios=true&redirect=/my-shopping-board#sign-in`
          }
          target="_blank"
        >
          view/add to shopping board
        </a>

        <Box mt={2.5}>
          <Grid container spacing={1}>
            {boardInfo?.boardItems.map((product: any) => (
              <Grid
                item
                md={4}
                sm={4}
                xs={6}
                style={{
                  userSelect: 'none',
                  paddingTop: 0,
                  paddingBottom: 40,
                }}
                className={classes.SBCard}
              >
                <MUIcard
                  className={classes.card}
                  style={{ position: 'relative' }}
                >
                  <div className={classes.cardArea}>
                    <div className={classes.cardMediaWrapper}>
                      <div className={classes.cardMediaWrapperInner}>
                        {product.type === 'product' && (
                          <CHRImage
                            errStyle={classes.errImage}
                            alt={product.data.name}
                            src={product.data.imageUrl || product.data.base64}
                            title={product.data.name}
                            className={classes.cardMedia}
                          />
                        )}
                      </div>
                    </div>
                    <CardContent className={classes.cardContent}>
                      {product.type === 'product' ? (
                        <>
                          <div className={classes.boxInfo}>
                            <Typography
                              component="h2"
                              className={classes.cardName}
                            >
                              {product.data.name}
                            </Typography>
                            <Typography
                              component="p"
                              className={classes.cardBrandName}
                            >
                              {product.data.brandName}
                            </Typography>
                          </div>
                          <div className={classes.boxPrices}>
                            <div className={classes.saleBox}>
                              {Number(product.data.price?.slice(1)) <
                              Number(product.data.originalPrice?.slice(1)) ? (
                                <div>
                                  <Typography
                                    component="p"
                                    className={classes.cardPrice}
                                    style={{
                                      textDecoration: 'line-through',
                                    }}
                                  >
                                    {product.data.originalPrice}
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className={classes.cardPrice}
                                  >
                                    {product.data.price}
                                  </Typography>
                                </div>
                              ) : (
                                <Typography
                                  component="p"
                                  className={classes.cardPrice}
                                >
                                  {product.data.price}
                                </Typography>
                              )}
                            </div>
                            {product.data.cashBack && (
                              <Typography
                                component="p"
                                className={classes.cashPercentage}
                              >
                                (<strong>{product.data.cashBack}</strong> cash
                                back)
                              </Typography>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={classes.boxInfo}>
                            <div className={classes.cardIngMediaWrap}>
                              <CHRImage
                                errStyle={classes.errIgImage}
                                src={product?.data?.profile_image || ''}
                                title={product.data.username}
                                alt={product.data.username}
                                className={`${classes.cardMedia} ${classes.cardIngMediaWrap}`}
                              />
                            </div>
                            <Typography
                              component="p"
                              className={classes.cardIngText}
                            >
                              @{product.data.username}
                            </Typography>
                          </div>
                        </>
                      )}
                    </CardContent>
                  </div>
                </MUIcard>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    </div>
  )
}

export default ShareLinkApp
