import React, { useState, useEffect } from 'react'
import {
  BottomNavigation,
  BottomNavigationAction,
  Typography,
} from '@material-ui/core'
import SEO from '../seo'
import Brands from './brandsApp'
import CommunityApp from './communityApp'
import BlogApp from './blogApp'
import HomeApp from './homeApp'
import axios from '../../axios'
import { COLORS, ENDPOINTS } from '../../constants'

import home from '../../assets/images/app-nav-home.svg'
import brandsIcon from '../../assets/images/app-nav-search.svg'
import users from '../../assets/images/app-nav-users.svg'
import blog from '../../assets/images/app-nav-blog.svg'
import chrLogo from '../../assets/images/chirpyest.svg'
import chirpyestTextLogo from '../../assets/images/chirpyest-text-logo.svg'
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded'
import ShareLink from './shareLinkApp'
import share from '../../assets/images/app-nav-share-2.svg'

import { styles } from './styles'
import AccountApp from './accountApp'
import WelcomeApp from './welcomeApp'

const Tab = ({ isSelected, icon, label, ...props }: any) => {
  return (
    <BottomNavigationAction
      icon={icon}
      label={label}
      style={{
        padding: 0,
        borderTop: isSelected
          ? '2px solid #000'
          : `2px solid ${COLORS.lightTeal}`,
      }}
      {...props}
    />
  )
}

const LoadingScreen = () => {
  const classes = styles()
  return (
    <div
      className={`${classes.loadingScreenWrapper} ${classes.flashAnimation}`}
    >
      <img src={chrLogo} className={classes.logo} alt="Chirpyest Logo" />
      <img
        src={chirpyestTextLogo}
        alt="Chirpyest"
        className={classes.textLogo}
      />
      <p>shop. share. earn cash back.</p>
    </div>
  )
}

function App() {
  const classes = styles()

  const [selected, setSelected] = useState<number>(0)

  const [username, setUsername] = useState<string>(
    window.localStorage.getItem('storedUsername') || ''
  )
  const [userId, setUserId] = useState<string>(
    window.localStorage.getItem('storedUserId') || ''
  )
  // const [username, setUsername] = useState<string>('hashem7')
  // const [userId, setUserId] = useState<string>('13959')

  const [isLoading, setIsLoading] = useState(false)
  const [userInfo, setUserInfo] = useState<any>(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isNew, setIsNew] = useState<boolean | null>(null)

  useEffect(() => {
    const updateData = (_mutations: any, observer: any) => {
      const targetNode = document.getElementById('userNameValue')
      const targetIdNode = document.getElementById('userIdValue')

      const injectedUsername = targetNode?.textContent || ''
      const injectedUserId = targetIdNode?.textContent || ''

      if (injectedUsername && injectedUserId) {
        window.localStorage.setItem('storedUsername', injectedUsername)
        window.localStorage.setItem('storedUserId', injectedUserId)

        setUsername(injectedUsername)
        setUserId(injectedUserId)
        setIsLoggedIn(true)

        observer.disconnect() // Stop observing after data is found
      }
    }

    const observer = new MutationObserver(updateData)
    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    // handle displaying first screen for the first time then hide it
    const howItWorksStatus = window.localStorage.getItem('howItWorksStatus')
    if (howItWorksStatus) {
      setIsNew(false)
    } else {
      setIsNew(true)
      window.localStorage.setItem('howItWorksStatus', 'skipped')
    }
  }, [])

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        setIsLoading(true)
        let endpoint = ENDPOINTS.shoppingBoardNonUser.replace(':id', username)
        const {
          data: { data },
        } = await axios.get(`${endpoint}`)
        setUserInfo(data.userInfo)
        setIsLoggedIn(true)
      } catch (e) {
        console.error(e)
        setIsLoggedIn(false)
      } finally {
        setIsLoading(false)
      }
    }

    if (username) {
      getUserInfo()
    }
  }, [username])

  const getAuthenticationStatus = (isLoggedIn: boolean) => {
    setIsLoggedIn(isLoggedIn)
  }

  if (isLoading || isNew === null) return <LoadingScreen />

  if (isNew) return <WelcomeApp setIsNew={setIsNew} />

  return (
    <>
      <SEO title="Chirpyest App" />
      <div className={classes.screenContainer}>
        <div className={classes.header}>
          <div onClick={() => setSelected(5)} className={classes.imageWrapper}>
            {userInfo?.image && isLoggedIn ? (
              <img
                src={`https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${userInfo?.image}`}
                alt="profile"
                className={classes.userImage}
              />
            ) : (
              <AccountCircleRounded className={classes.userImage} />
            )}
          </div>
          {selected === 0 && (
            <Typography variant="body1" className={classes.headerText}>
              <img
                src={chirpyestTextLogo}
                className={classes.homeHeaderLogo}
                alt="chirpyest-text-logo"
              />
            </Typography>
          )}
          {selected === 1 && (
            <Typography variant="body1" className={classes.headerText}>
              retailers
            </Typography>
          )}
          {selected === 2 && (
            <Typography variant="body1" className={classes.headerText}>
              share + earn
            </Typography>
          )}
          {selected === 3 && (
            <Typography variant="body1" className={classes.headerText}>
              community
            </Typography>
          )}
          {selected === 4 && (
            <Typography variant="body1" className={classes.headerText}>
              blog
            </Typography>
          )}
          {selected === 5 && (
            <Typography variant="body1" className={classes.headerText}>
              account
            </Typography>
          )}
          <img src={chrLogo} className={classes.logo} />
        </div>
        <div>
          <div className={classes.tabContent}>
            {selected === 0 && (
              <HomeApp userImage={userInfo?.image} isLoggedIn={isLoggedIn} />
            )}
            {selected === 1 && <Brands userId={userId} userName={username} />}
            {selected === 2 && <ShareLink />}
            {selected === 3 && <CommunityApp username={username} />}
            {selected === 4 && <BlogApp />}
            {selected === 5 && (
              <AccountApp
                getAuthenticationStatus={getAuthenticationStatus}
                setSelected={setSelected}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
              />
            )}
          </div>
        </div>
        <BottomNavigation
          value={selected}
          onChange={(value, newValue) => {
            setSelected(newValue)
          }}
          className={classes.bottomNav}
          showLabels
        >
          <Tab
            isSelected={selected === 0}
            label={
              <Typography variant="body1" className={classes.tabLabel}>
                Home
              </Typography>
            }
            icon={<img src={home} />}
          />

          <Tab
            isSelected={selected === 1}
            label={
              <Typography variant="body1" className={classes.tabLabel}>
                Brands
              </Typography>
            }
            icon={<img src={brandsIcon} />}
          />

          <Tab
            isSelected={selected === 2}
            label={
              <Typography variant="body1" className={classes.tabLabel}>
                Sharing Tools
              </Typography>
            }
            icon={<img src={share} />}
          />
          <Tab
            isSelected={selected === 3}
            label={
              <Typography variant="body1" className={classes.tabLabel}>
                Community
              </Typography>
            }
            icon={<img src={users} />}
          />

          <Tab
            isSelected={selected === 4}
            label={
              <Typography variant="body1" className={classes.tabLabel}>
                Blog{' '}
              </Typography>
            }
            icon={<img src={blog} />}
          />
        </BottomNavigation>
      </div>
    </>
  )
}

export default App
