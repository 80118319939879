import React, { useState } from 'react'
import { Box } from '@material-ui/core'

import Button from '../../button'
import arrow from '../../../assets/images/brands-arrow.svg'
import birdAvatar from '../../../assets/images/bird-avatar.png'
import facebookIcon from '../../../assets/images/facebook.svg'
import instagramIcon from '../../../assets/images/instagram.svg'
import pinterestIcon from '../../../assets/images/pinterest.svg'
import tiktokIcon from '../../../assets/images/tiktok.svg'
import WebsiteIcon from '@material-ui/icons/Language'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { SOCIAL_MEDIA_LINKS } from '../../../constants'

import { styles } from './styles'

const AccountApp = ({
  getAuthenticationStatus,
  setSelected,
  userInfo,
  setUserInfo,
}: any) => {
  const classes = styles()
  const [isCopied, setIsCopied] = useState(false)

  const userJoinLink =
    userInfo &&
    `${new URL(process.env.GATSBY_SITE_URL as string).host.replace(
      'www.',
      ''
    )}/join/${userInfo.username}-${userInfo.id}`

  const handleSignOut = () => {
    const targetNode = document.getElementById('userNameValue') as HTMLElement
    const targetIdNode = document.getElementById('userIdValue') as HTMLElement

    targetNode.textContent = ''
    targetIdNode.textContent = ''
    window.localStorage.removeItem('storedUsername')
    window.localStorage.removeItem('storedUserId')
    getAuthenticationStatus(false)
    setUserInfo(null)
  }

  const copyToClipboard = () => {
    const textField = document.createElement('textarea')
    textField.innerText = userJoinLink
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    setIsCopied(true)
    textField.remove()
  }

  return !userInfo ? (
    <a
      className={classes.link}
      href={`${process.env.GATSBY_SITE_URL}/app/open?redirect=/app/open`}
      target="_blank"
    >
      <h2 className={classes.linkText}>click here to sign in</h2>
      <img src={arrow} alt="arrow" className={classes.arrow} />
    </a>
  ) : (
    <Box mb={5}>
      <div className={classes.greetingWrapper}>
        <p className={classes.greeting}>hi {userInfo.username}</p>
        <div onClick={handleSignOut} className={classes.signOut}>
          <a
            className={classes.signOutLink}
            href={`${process.env.GATSBY_SITE_URL}/user/member/?signOutFromApp=true&ios=true`}
            target="_blank"
          >
            sign out
          </a>
        </div>
      </div>
      <div className={classes.infoWrapper}>
        <img
          src={
            userInfo.image
              ? `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${userInfo.image}`
              : birdAvatar
          }
          alt="profile"
          className={classes.profileImg}
        />
        <div className={classes.textWrapper}>
          {userInfo.username && (
            <h4 className={classes.userName}>{userInfo.username}</h4>
          )}
          {userInfo.shortBio && (
            <p className={classes.text}>{userInfo.shortBio}</p>
          )}
        </div>
        {userInfo?.financialData?.lastClosedOut ? (
          <p className={classes.text}>
            ${Number(userInfo.financialData.lastClosedOut).toFixed(2)} cash back
            earned this month
          </p>
        ) : null}
      </div>
      <div className={classes.linksWrapper}>
        <a
          className={classes.link}
          href={`${process.env.GATSBY_SITE_URL}/user/dashboard/?ios=true#cashback`}
          target="_blank"
        >
          <h2 className={classes.linkText}>cash back</h2>
          <img src={arrow} alt="arrow" className={classes.arrow} />
        </a>
        <a
          className={classes.link}
          href={`${process.env.GATSBY_SITE_URL}/user/dashboard/?ios=true#paymentInfo`}
          target="_blank"
        >
          <h2 className={classes.linkText}>payment info</h2>
          <img src={arrow} alt="arrow" className={classes.arrow} />
        </a>
        <a
          className={classes.link}
          href={`${process.env.GATSBY_SITE_URL}/user/dashboard/?ios=true#settings`}
          target="_blank"
        >
          <h2 className={classes.linkText}>edit profile</h2>
          <img src={arrow} alt="arrow" className={classes.arrow} />
        </a>
        <div
          className={classes.link}
          onClick={() => {
            window.scrollTo(0, 0)
            setSelected(0)
          }}
        >
          <h2 className={classes.linkText}>how it works</h2>
          <img src={arrow} alt="arrow" className={classes.arrow} />
        </div>
      </div>
      <Box ml={2.5} mr={2.5}>
        <h3 className={classes.shareTitle}>share chirpyest</h3>
        <div className={classes.shareWrapper}>
          <p className={classes.shareText}>
            <span className={classes.boldText}>
              refer a friend, get $10! use join link
            </span>{' '}
            <span className={classes.underline}>{userJoinLink}</span>
          </p>
          {document.queryCommandSupported('copy') && (
            <Button
              onClick={copyToClipboard}
              customStyle={classes.button}
              customTextStyle={classes.buttonText}
              label={isCopied ? 'copied' : 'copy link'}
            />
          )}
        </div>
      </Box>
      <Box ml={1.25}>
        <ul className={classes.socialMediaList}>
          <li className={classes.socialListItem}>
            <a
              href={SOCIAL_MEDIA_LINKS.facebook}
              target="_blank"
              className={classes.socialIcon}
              rel="noreferrer"
            >
              <img src={facebookIcon} alt="facebook" width="15" height="15" />
            </a>
          </li>
          <li className={classes.socialListItem}>
            <a
              href={SOCIAL_MEDIA_LINKS.instargram}
              target="_blank"
              className={classes.socialIcon}
              rel="noreferrer"
            >
              <img src={instagramIcon} alt="instagram" width="15" height="15" />
            </a>
          </li>
          <li className={classes.socialListItem}>
            <a
              href={SOCIAL_MEDIA_LINKS.tiktok}
              target="_blank"
              className={classes.socialIcon}
              rel="noreferrer"
            >
              <img src={tiktokIcon} alt="tiktok" width="15" height="15" />
            </a>
          </li>
          <li className={classes.socialListItem}>
            <a
              href={SOCIAL_MEDIA_LINKS.pinterest}
              target="_blank"
              className={classes.socialIcon}
              rel="noreferrer"
            >
              <img src={pinterestIcon} alt="pinterest" width="15" height="15" />
            </a>
          </li>
          <li className={classes.socialListItem}>
            <a
              href={process.env.GATSBY_SITE_URL}
              target="_blank"
              className={classes.socialIcon}
              rel="noreferrer"
            >
              <WebsiteIcon className={classes.socialMediaIcon} />
            </a>
          </li>
          <li className={classes.socialListItem}>
            <a
              href={`${process.env.GATSBY_SITE_URL}/company#contact-us`}
              target="_blank"
              className={classes.socialIcon}
              rel="noreferrer"
            >
              <MailOutlineIcon className={classes.socialMediaIcon} />
            </a>
          </li>
        </ul>
      </Box>
    </Box>
  )
}

export default AccountApp
