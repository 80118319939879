import { makeStyles } from '@material-ui/core/styles'
import { FONTS } from '../../../constants'

export const styles = makeStyles(() => ({
  homeWrapper: {
    marginLeft: '20px',
    marginRight: '20px',
  },
  headerText: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    margin: '0px',
  },
  homeTitle: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  descriptionText: {
    fontSize: '12px',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontWeight: 400,
    marginBottom: '40px',
    marginTop: '5px',
  },
  logo: {
    maxWidth: '25px',
    maxHeight: '25px',
    marginBottom: '5px',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  userImage: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
  },
}))
