import React, { useState } from 'react'
import { useEffect } from 'react'
import axios from '../../axios'
import errorImg from '../../assets/images/error-image.svg'
import { baseURL } from '../../constants'

const IgImageView = ({ url, className }) => {
  const [loading, setLoading] = useState(true)
  const [img, setImg] = useState(null)
  const [err, setErr] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        let igImageUrl = url
        let host = new URL(url).host
        if (host === new URL(baseURL).host) {
          let data = await axios.get(
            `/api/v1/ig?igUrl=${encodeURIComponent(url + 'media/?size=l')}`
          )

          igImageUrl = data.data
        }

        setImg(igImageUrl)
        setLoading(false)
      } catch (err) {
        setImg(errorImg)
        setErr(errorImg)
        setLoading(false)
      }
    })()
  }, [])

  return loading ? (
    <div />
  ) : (
    <img src={img} className={className} alt="largeImageView" />
  )
}

export default IgImageView
