import React, { useState, useCallback, useEffect } from 'react'
import { Typography, Grid } from '@material-ui/core'
import { useLocation } from '@reach/router'
import { parse } from 'query-string'
import debounce from 'lodash.debounce'
import CHRInput from '../../input'
import { ENDPOINTS } from '../../../constants'
import axios from '../../../axios'
import Card from './card'
import CHRSpinner from '../../spinner'
import CHROutlinedButton from '../../outlinedButton'
import searchIcon from '../../../assets/images/app-nav-search.svg'
import { styles } from './styles'
import CHRaxios from '../../../axios'

interface UserProps {
  image: string
  name: string
  user_id: number
  items: any
  description: string
  username: string
  short_bio: string
}

interface CommunityInfoProps {
  finishedLoading: boolean
  offset: number
  size: number
  data: UserProps[]
}

interface ShareLinkAppProps {
  username: string
}

const DEFAULT_SIZE = 30
const ShareLinkApp = ({ username }: ShareLinkAppProps) => {
  const classes = styles()
  const { search } = useLocation()
  const { q } = parse(search)
  const [searchText, setSearchText] = useState(q || '')
  const [isLoading, setIsLoading] = useState(true)
  const [usersResults, setUsersResults] = useState({
    data: [],
    finishedLoading: true,
    offset: 0,
    size: 30,
  })
  const [error, setError] = useState('')

  const getUsersResults = async () => {
    const {
      data: { data: results },
    } = await CHRaxios.get(`${ENDPOINTS.getTheChirpyest}?offset=0&size=30`)
    setUsersResults({
      finishedLoading:
        results.length === 0 || results.length < usersResults.size,
      offset: usersResults.offset + usersResults.size,
      size: 30,
      data: usersResults.data.concat(results.sort(() => 0.5 - Math.random())),
    })
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)

    const prevState = sessionStorage.getItem('the-chirpyest')
    if (prevState) {
      setUsersResults(JSON.parse(prevState))
      setIsLoading(false)
      sessionStorage.removeItem('the-chirpyest')

      const scrollPos = sessionStorage.getItem('the-chirpyest-scroll-pos')
      setTimeout(() => window.scrollTo(0, Number(scrollPos)), 100)
      sessionStorage.removeItem('the-chirpyest-scroll-pos')
    } else {
      getUsersResults()
    }
  }, [username])

  // const getUsersResults = async () => {
  //   try {
  //     const {
  //       data: { data: results },
  //     } = await axios.get(
  //       `${ENDPOINTS.getTheChirpyest}?offset=${usersResults.offset}&size=${usersResults.size}&q=${searchText}`
  //     )
  //     setUsersResults({
  //       finishedLoading:
  //         results.length === 0 || results.length < usersResults.size,
  //       offset: usersResults.offset + usersResults.size,
  //       size: DEFAULT_SIZE,
  //       data: usersResults.data.concat(results.sort(() => 0.5 - Math.random())),
  //     })
  //   } catch (err) {
  //     setError(
  //       'something went wrong. please try refreshing the app or try again later.'
  //     )
  //   }

  //   setIsLoading(false)
  // }

  const fetchResults = async (query: string) => {
    try {
      const {
        data: { data: results },
      } = await axios.get(
        `${ENDPOINTS.getTheChirpyest}?offset=0&size=${usersResults.size}&q=${query}`
      )
      setUsersResults({
        finishedLoading:
          results.length === 0 || results.length < usersResults.size,
        offset: usersResults.size,
        size: DEFAULT_SIZE,
        data: results.sort(() => 0.5 - Math.random()),
      })
    } catch (err) {
      setError(
        'something went wrong. please try refreshing the app or try again later.'
      )
    }
  }

  const debouncedSave = useCallback(
    debounce((q: string) => fetchResults(q), 300),
    []
  )

  const updateValue = (q: string) => {
    setSearchText(q)
    debouncedSave(q)
  }

  if (isLoading) return <CHRSpinner />

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>browse chirpyest member boards</h2>
      <div className={classes.searchContainer}>
        <img
          src={searchIcon}
          alt="Search Icon"
          className={classes.searchIcon}
        />
        <CHRInput
          type="text"
          value={searchText}
          placeholder="search the chirpyest community"
          onChange={e => updateValue(e.target.value)}
          customStyles={classes.searchInput}
        />
      </div>
      {isLoading ? (
        <CHRSpinner />
      ) : (
        <Grid
          container
          alignItems="stretch"
          spacing={2}
          className={classes.mainGrid}
        >
          {usersResults.data.map(
            ({
              user_id: userId,
              image: userImage,
              items,
              description,
              username,
              short_bio: blurb,
            }: UserProps) => (
              <Grid item lg={4} md={4} sm={4} xs={6} key={userId}>
                <a
                  href={`${process.env.GATSBY_SITE_URL}/s/${username}`}
                  className={classes.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Card
                    userImage={userImage}
                    items={items}
                    description={description}
                    userName={username}
                    blurb={blurb}
                  />
                </a>
              </Grid>
            )
          )}
        </Grid>
      )}
      {!isLoading && !usersResults.data.length && (
        <Typography variant="h3" className={classes.noData}>
          sorry, there is no data
        </Typography>
      )}
      {!usersResults.finishedLoading && (
        <CHROutlinedButton
          label="load more"
          onClick={getUsersResults}
          customStyle={classes.loadMore}
        />
      )}
      {error && (
        <Typography variant="subtitle1" component="p" className={classes.error}>
          {error}
        </Typography>
      )}
    </div>
  )
}

export default ShareLinkApp
