import React, { useState } from 'react'

import chrLogo from '../assets/images/chirpyest.svg'
import chirpyestTextLogo from '../assets/images/chirpyest-text-logo.svg'

import { styles } from '../pagesStyles/wrongPassword'
import safariBrowser from '../assets/images/safari-browser.png'
import Button from '../components/button'
import { Typography } from '@material-ui/core'
import usePreviousLocation from '../hooks/usePreviousLocation'

declare const window: any

const WrongBrowser = () => {
  const classes = styles()
  const [buttonLabel, setButtonLabel] = useState('copy + paste link in safari')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const previousLocation: any = usePreviousLocation()

  const copyLinkToClipboard = () => {
    const linkToCopy = window.location.href.includes('?redirect=')
      ? previousLocation?.href
      : window.location.href

    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        setButtonLabel('link copied!')
        setIsButtonDisabled(true)
      })
      .catch(err => {
        console.error('Failed to copy link: ', err)
      })
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div />
        <img
          src={chirpyestTextLogo}
          className={classes.homeHeaderLogo}
          alt="chirpyest-text-logo"
        />
        <img src={chrLogo} className={classes.logo} />
      </div>

      <div className={classes.content}>
        <Typography variant="body1" className={classes.title}>
          get the mobile browser extension
        </Typography>
        <img src={safariBrowser} className={classes.safariLogo} />
        <Typography variant="body1" className={classes.title}>
          go to safari{' '}
        </Typography>
        <p className={classes.descriptionText}>
          chirpyest mobile browser extension only works in the safari browser.
          click below to open in safari.
        </p>

        <Button
          onClick={copyLinkToClipboard}
          customStyle={
            isButtonDisabled ? classes.disabledButton : classes.button
          }
          customTextStyle={classes.buttonText}
          label={buttonLabel}
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  )
}

export default WrongBrowser
