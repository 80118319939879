import React, { useState, useEffect, useCallback } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { styles } from './styles'
import {
  checkIfSubmittable,
  profileInfoValidation,
} from '../../../../../utils/validation'
import { COLORS, ENDPOINTS, baseURL } from '../../../../constants'
import axios from '../../../../axios'
import { useAppState } from '../../../../appState'
import { Box, DialogTitle, IconButton, Typography } from '@material-ui/core'
import chirpyestTextLogo from '../../../../assets/images/chirpyest-text-logo.svg'
import chrLogo from '../../../../assets/images/chirpyest.svg'
import SignInInput from '../../signInInput'
import SignInButton from '../../signInButton'
import { navigate } from 'gatsby'
import CHRHeaderDialog from '../../../headerDialog'
import burgerMenuIcon from '../../../../assets/images/burger-menu.svg'

const parseFirstName = (fullName: string | undefined): string => {
  if (!fullName) return ''
  return fullName.split(' ')[0] || ''
}
const parseLastName = (fullName: string | undefined): string => {
  if (!fullName) return ''
  return fullName.split(' ')[1] || ''
}

const JoinDetailsApp = () => {
  const classes = styles()
  // @ts-ignore
  const [state, dispatch] = useAppState()
  const [userInfo, setUserInfo] = useState({
    firstName: parseFirstName(state.userProfile.fullName),
    lastName: parseLastName(state.userProfile.fullName),
    userName: state.userProfile.userName || '',
  })
  const [userInfoErrors, setUserInfoErrors] = useState({
    firstNameMsg: '',
    lastNameMsg: '',
    userNameMsg: '',
  })
  const [requestStatus, setRequestStatus] = useState({
    requestSuccessMsg: '',
    requestErrorMsg: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isOverlayOpen, setOverlayStatus] = useState(false)

  const openMenuOverlay = useCallback(() => {
    setOverlayStatus(true)
  }, [])

  const closeMenuOverlay = useCallback(() => {
    setOverlayStatus(false)
  }, [])

  useEffect(() => {
    if (requestStatus.requestSuccessMsg) {
      setTimeout(() => {
        navigate('/app/open?fromLogin=true')
      }, 2000)

      dispatch({
        type: 'UPDATE_USER_DATA',
        userProfile: {
          ...state.userProfile,
          userName: userInfo.userName,
          fullName: userInfo.firstName + ' ' + userInfo.lastName,
        },
      })
    }
    return
  }, [requestStatus.requestSuccessMsg])

  const handleTextChange = (event: any, fieldName: string) => {
    setUserInfoErrors({
      firstNameMsg: '',
      lastNameMsg: '',
      userNameMsg: '',
    })
    setUserInfo({ ...userInfo, [fieldName]: event.target.value })
  }

  const handleInfoSubmission = async () => {
    setIsSubmitting(true)
    try {
      const isFormValid = await profileInfoValidation({
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        userName: userInfo.userName,
      })

      const userData = {
        name: `${userInfo.firstName} ${userInfo.lastName}`,
        username: userInfo.userName,
      }

      const result = await axios.patch(
        `${baseURL}/${ENDPOINTS.user.replace(':id', state.userId)}`,
        userData
      )

      if (result) {
        // @ts-ignore
        setRequestStatus({
          requestSuccessMsg:
            'your information has been updated successfully. you will be redirected now!',
        })
      }
      // navigate(ROUTES.personalInfo)
    } catch (error) {
      if (error.errors) {
        const errors = error?.inner?.reduce(
          (accumulator: {}, ele: any) => {
            // @ts-ignore
            accumulator[ele?.path] = ele.message
            return accumulator
          },
          { firstName: '', lastName: '', userName: '' }
        )
        setUserInfoErrors({
          firstNameMsg: errors.firstName,
          lastNameMsg: errors.lastName,
          userNameMsg: errors.userName,
        })
      } else {
        if (error.response?.data?.message === 'username is already exists')
          // @ts-ignore
          setUserInfoErrors({ userNameMsg: 'username already exists' })
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const buttonProps = [
    {
      onClick: handleInfoSubmission,
      label: "let's go",
      customButtonStyle: { backgroundColor: COLORS.lightTeal },
      customIconStyle: { width: '16px', height: '13.091px' },
      id: 1,
    },
  ]

  return (
    <Dialog fullScreen={true} open={true} aria-labelledby="personalInfo">
      {/* Dialog Header */}
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={openMenuOverlay}
          >
            <img
              src={burgerMenuIcon}
              className={classes.menuIcon}
              alt={'menu'}
            />
          </IconButton>

          <img
            src={chirpyestTextLogo}
            className={classes.homeHeaderLogo}
            alt="chirpyest-text-logo"
          />
          <img src={chrLogo} className={classes.logo} />
        </Box>
      </DialogTitle>

      <Box component="section" className={classes.dialogContent} mb={7.5}>
        {/* Title & Description */}
        <Typography variant="h2" className={classes.title}>
          one last step...
        </Typography>

        <Typography variant="subtitle1" className={classes.text}>
          add your name and username to complete your account set-up
        </Typography>

        <Box className={classes.credentials}>
          <Box className={classes.credentialBox}>
            <SignInInput
              value={userInfo.firstName}
              onChange={event => handleTextChange(event, 'firstName')}
              placeholder="first name"
              type="text"
              error={!!userInfoErrors.firstNameMsg}
              required
              onKeyPress={(event: any) =>
                !isSubmitting && checkIfSubmittable(event, handleInfoSubmission)
              }
            />
          </Box>
          {userInfoErrors.firstNameMsg && (
            <p className={classes.errorMessage}>
              {userInfoErrors.firstNameMsg}
            </p>
          )}

          <Box className={classes.credentialBox}>
            <SignInInput
              value={userInfo.lastName}
              onChange={event => handleTextChange(event, 'lastName')}
              placeholder="last name"
              type="text"
              error={!!userInfoErrors.lastNameMsg}
              required
              onKeyPress={(event: any) =>
                !isSubmitting && checkIfSubmittable(event, handleInfoSubmission)
              }
            />
          </Box>
          {userInfoErrors.lastNameMsg && (
            <p className={classes.errorMessage}>{userInfoErrors.lastNameMsg}</p>
          )}

          <Box className={classes.credentialBox}>
            <SignInInput
              value={userInfo.userName}
              onChange={event => handleTextChange(event, 'userName')}
              placeholder="username"
              type="text"
              error={!!userInfoErrors.userNameMsg}
              required
              onKeyPress={(event: any) =>
                !isSubmitting && checkIfSubmittable(event, handleInfoSubmission)
              }
            />
          </Box>
          {userInfoErrors.userNameMsg && (
            <p className={classes.errorMessage}>{userInfoErrors.userNameMsg}</p>
          )}
        </Box>

        <Box>
          {buttonProps.map((button: any) => (
            <SignInButton
              key={button.id}
              onClick={button.onClick}
              label={button.label}
              icon={button.icon}
              isSubmitting={isSubmitting}
              disabled={isSubmitting}
              customIconStyle={button.customIconStyle}
              customButtonStyle={button.customButtonStyle}
            />
          ))}
          {requestStatus.requestSuccessMsg && (
            <p className={classes.passwordGuidelines}>
              {requestStatus.requestSuccessMsg}
            </p>
          )}
        </Box>
      </Box>
      <CHRHeaderDialog
        handleClose={closeMenuOverlay}
        openStatus={isOverlayOpen}
        isFromIos
      />
    </Dialog>
  )
}

export default JoinDetailsApp
