import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import CHRSpinner from '../../spinner'

import { styles } from './styles'
import { ENDPOINTS } from '../../../constants'
import axios from '../../../axios'

const BlogApp = () => {
  const classes = styles()
  const [blogs, setBlogs] = useState({
    isLoading: true,
    info: [],
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)

    let endpoint = `${ENDPOINTS.blogs}?top=10`
    const getBlogs = async () => {
      try {
        const {
          data: { data },
        } = await axios.get(`${endpoint}`)
        setBlogs({
          isLoading: false,
          info: data,
        })
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        console.error(e)
      }
    }
    getBlogs()
  }, [])

  if (isLoading) return <CHRSpinner />
  return (
    <div>
      <div className={classes.trendingContainer}>
        <h3 className={classes.trendingTitle}>trending posts</h3>
        <div className={classes.heroPostsWrapper}>
          {blogs.info.slice(0, 3).map(({ image, title, link }) => (
            <a href={link} className={classes.heroBlogLink} target="_blank">
              <img src={image} className={classes.heroBlogImg} />
              <h3 className={classes.blogTitle}>{title}</h3>
            </a>
          ))}
        </div>
      </div>
      {blogs.isLoading ? (
        <CHRSpinner />
      ) : (
        <div className={classes.blogsContainer}>
          <Grid container justifyContent="space-between">
            {blogs.info.slice(3, 9).map(({ image, title, link }) => (
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className={classes.blogItemContainer}
              >
                <a className={classes.blogLink} href={link} target="_blank">
                  <img src={image} className={classes.blogImg} />
                  <h3 className={classes.blogTitle}>{title}</h3>
                </a>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  )
}

export default BlogApp
