import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS } from '../constants'

export const styles = makeStyles(() => ({
  container: {
    padding: '50px 20px 0',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    width: '26px',
    height: '28px',
  },
  homeHeaderLogo: {
    width: '136px',
    height: '35px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 40px 0',
    maxWidth: '508px',
    margin: '0 auto',
  },
  safariLogo: {
    width: '250px',
    height: '250px',
    margin: '20px 0',
  },
  button: {
    backgroundColor: `${COLORS.lightTeal} !important`,
    borderColor: COLORS.lightTeal,
    border: '1px solid #000000 !important',
    maxHeight: '40px',
    maxWidth: '250px',
    margin: 0,
  },
  disabledButton: {
    backgroundColor: `${COLORS.ashGrey} !important`,
    borderColor: 'none !important',
    pointerEvents: 'none',
    maxHeight: '40px',
    maxWidth: '200px',
    margin: 0,
  },
  buttonText: {
    color: '#000000 !important',
    fontSize: '13px',
  },
  title: {
    fontSize: '28px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    lineHeight: '100%',
    textAlign: 'center',
  },
  descriptionText: {
    fontSize: '12px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    margin: '10px 0 30px',
    textAlign: 'center',
  },
}))
