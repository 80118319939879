import { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'

declare const window: any

function usePreviousLocation() {
  const location = useLocation()
  const [prevLocation, setPrevLocation] = useState(window.prevLocation)

  useEffect(() => {
    setPrevLocation((prev: any) => {
      return prev.href !== location.href ? prev : location
    })
  }, [location])

  return prevLocation
}

export default usePreviousLocation
