import * as React from 'react'
import classNames from 'classnames'

import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded'

import brandsIcon from '../../../assets/images/app-nav-search.svg'
import share from '../../../assets/images/app-nav-share-2.svg'
import cashback from '../../../assets/images/cashback-logo.svg'

import { styles } from './styles'

const HomeApp = ({ userImage, isLoggedIn }: any) => {
  const classes = styles()

  return (
    <div className={classes.homeWrapper}>
      <h3 className={classNames(classes.homeTitle, classes.headerText)}>
        how chirpyest works
      </h3>

      <img src={brandsIcon} className={classes.logo} />
      <h3 className={classes.headerText}>brands</h3>
      <p className={classes.descriptionText}>
        see the entire list of retailers. click a brand, you will be redirected
        to the retailer site and cash back will automatically be activated.
      </p>

      <img src={share} className={classes.logo} />
      <h3 className={classes.headerText}>sharing tools</h3>
      <p className={classes.descriptionText}>
        create a “shopping board” or “share link” for products and earn when
        someone else shops.
      </p>

      <img src={cashback} alt="cashback" className={classes.imageWrapper} />
      <h3 className={classes.headerText}>shop online</h3>
      <p className={classes.descriptionText}>
        shop anywhere online and the chirpyest extension will pop up. press
        “activate cash back” and earn.
      </p>

      <div className={classes.imageWrapper}>
        {userImage && isLoggedIn ? (
          <img
            src={`https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${userImage}`}
            alt="profile"
            className={classes.userImage}
          />
        ) : (
          <AccountCircleRounded className={classes.userImage} />
        )}
      </div>

      <h3 className={classes.headerText}>your account</h3>
      <p className={classes.descriptionText}>
        tap circle on upper left of screen to manage account and to see cash
        back balance.
      </p>
    </div>
  )
}

export default HomeApp
