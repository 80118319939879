import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS, COLORS } from '../../../../constants'

export const styles = makeStyles(theme => ({
  dialogTitle: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    color: theme.palette.common.black,
  },
  toolbar: {
    position: 'relative',
    padding: '20px 15px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    fontSize: '32px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    margin: 0,
  },
  homeHeaderLogo: {
    width: '136px',
    height: '35px',
  },
  logo: {
    width: '26px',
    height: '28px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0 40px',
    justifyContent: 'center',
    height: 'calc(100vh - 64px)',
  },
  text: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontStyle: 'normal',
    lineHeight: '21px',
    letterSpacing: '0.156px',
    textTransform: 'lowercase',
    margin: '20px 0',
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: '300px',
    },
  },
  credentials: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  credentialBox: {
    width: '250px',
    height: '40px',
    gap: 10,
    marginTop: 10,
  },
  errorMessage: {
    color: COLORS.watermelonPink,
    fontFamily: FONTS.Graphik.GraphikRegular,
    margin: 0,
    fontSize: '10px',
    marginTop: '10px',
  },
  passwordGuidelines: {
    color: COLORS.coolTeal,
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '12px',
    lineHeight: '14px',
    marginTop: '20px',
  },
  menuIcon: {
    width: '24px',
    height: '11.2px',
  },
}))
