import React, { useState } from 'react'
import MainJoinApp from './mainJoinApp'
import JoinWithEmailApp from './joinWithEmailApp'
import JoinDetailsApp from './joinDetailsApp'
import WrongBrowser from '../../../pages/wrong-browser'
import { isIOS, isSafari } from 'react-device-detect'

// Types Declarations
interface ISignInProps {
  openStatus: boolean
}

const JoinApp = ({}: ISignInProps) => {
  const [currentView, setCurrentView] = useState(1)

  const handleNextStep = () => {
    setCurrentView(prev => prev + 1)
  }

  if (isIOS && !isSafari) {
    return <WrongBrowser />
  }

  const renderCurrentView = () => {
    switch (currentView) {
      case 1:
        return <MainJoinApp nextStep={handleNextStep} />
      case 2:
        return <JoinWithEmailApp nextStep={handleNextStep} />
      case 3:
        return <JoinDetailsApp />
      default:
        return null
    }
  }

  return renderCurrentView()
}

export default JoinApp
