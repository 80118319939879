import { Router } from '@reach/router'
import React from 'react'
import CHRApp from '../components/app'
import PrivateRoute from '../components/privateRoute'
// import PersonalInfoApp from '../components/app/personalInfo';
import NotFoundPage from './404'

import JoinApp from '../components/app/joinApp'
import LandingApp from '../components/app/landingApp'
import OpenApp from '../components/app/openApp'

const App = () => {
  return (
    <Router basepath={'/app'}>
      <CHRApp path="/" />
      <CHRApp path="/user" />
      <JoinApp path="/join" />
      <LandingApp path="/onboarding" />
      {/* <OpenApp path="/open" /> */}
      <PrivateRoute component={OpenApp} path={'/open'} isFromIos={true} />

      {/* <PrivateRoute component={CHRApp} path={'/user'} /> */}
      {/* <PrivateRoute component={PersonalInfoApp} path="/personal-info-app" /> */}
      <NotFoundPage default withLayout={false} />
    </Router>
  )
}

export default App
