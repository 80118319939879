export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

interface RetryOptions {
  maxRetries?: number;
  waitBetweenTries?: number;
  timeout?: number;
  retryCondition?: (error: any) => boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const timeoutPromise = async (ms: number, promise: Function) => {
  return await new Promise(async (resolve, reject) => {

    const timeoutId = setTimeout(() => {
      reject(new Error("promise timeout"))
    }, ms);

    try {
      const result = await promise();
      clearTimeout(timeoutId);
      resolve(result);
    } catch (err: any) {
      clearTimeout(timeoutId);
      reject(err);
    }
  })
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const callWithRetry = async (fn: Function, options: RetryOptions): Promise<any> => {
  let attemptCount = 0;
  const { maxRetries = 2, waitBetweenTries = 100, retryCondition, timeout = 0 } = options;
  /* eslint-disable no-await-in-loop */
  while (true) {
    try {
      let result;
      if (timeout > 0) {
        result = await timeoutPromise(timeout, fn)
      } else {
        result = await fn();
      }
      return result;
    } catch (error) {
      attemptCount += 1;
      const shouldRetry = retryCondition ? retryCondition(error) : true;
      if (attemptCount >= maxRetries || !shouldRetry) throw error;
    }
    await wait(waitBetweenTries * attemptCount);
  }
};


