import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../../../constants'

export const styles = makeStyles(theme => ({
  container: {
    padding: '0 20px 20px',
  },
  title: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontWeight: 500,
    marginTop: '20px',
    marginBottom: '10px',
  },
  text: {
    fontSize: '12px',
    fontFamily: FONTS.Graphik.GraphikLight,
    fontWeight: 400,
  },
  alertText: {
    fontSize: '14px',
  },
  errorMsg: {
    color: '#2E7C86',
    fontWeight: 700,
    marginBottom: 0,
  },
  infoMsg: {
    color: COLORS.infoBlue,
  },
  linkInput: {
    borderRadius: '27px',
  },
  buttons: {
    display: 'flex',
    margin: '10px -5px',
  },
  button: {
    backgroundColor: `${COLORS.lightTeal} !important`,
    borderColor: COLORS.lightTeal,
    border: '1px solid #000000 !important',
    maxHeight: '40px',
    marginLeft: '5px',
    marginRight: '5px',
    paddingRight: '4px',
    paddingLeft: '4px',
  },
  buttonText: {
    color: '#000000 !important',
    fontSize: '12px',
    letterSpacing: 1,
    lineHeight: 1.4,
  },
  viewBtn: {
    display: 'block',
    width: '100%',
    textDecoration: 'none',
    border: '1px solid #000',
    color: 'black',
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '12px',
    paddingTop: '13px',
    paddingBottom: '13px',
    textAlign: 'center',
    backgroundColor: COLORS.lightTeal,
    marginTop: '20px',
  },
  grayCard: {
    width: '100%',
    backgroundColor: '#F1F1F1',
    height: '230px',
    borderRadius: '3px',
  },
  cardImg: {
    maxWidth: '100%',
    maxHeight: '210px',
    objectFit: 'cover',
    margin: '0 auto',
  },
  cardBrand: {
    fontSize: '10px',
    fontFamily: FONTS.Graphik.GraphikRegular,
    margin: '5px 0',
    fontWeight: 400,
  },
  cardCashback: {
    color: '#175972',
  },
  SBCard: {
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      width: '100%',
      margin: '0 auto',
    },
  },
  card: {
    boxShadow: 'none',
  },
  cardArea: {
    ['& .MuiCardActionArea-focusHighlight']: {
      opacity: '0!important',
    },
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      position: 'static',
    },
  },
  cardMediaWrapper: {
    overflow: 'hidden',
    position: 'relative',
    minHeight: '210px',
    width: '100%',
    border: '1px solid #f6f6f6',
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      minHeight: '210px',
    },
    [`@media (min-width: ${BREAKPOINTS.xmd}px)`]: {
      minHeight: '194px',
    },
  },
  cardMediaWrapperInner: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    left: '50%',
    width: '100%',
    textAlign: 'center',
    ['& img']: {
      width: '100%',
      display: 'inline-block',
      height: 480,
      [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
        height: 240,
      },
    },
  },
  cardMedia: {
    objectFit: 'contain',
  },
  cardContent: {
    padding: 10,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      padding: '18px 0',
    },
  },
  cardIngMediaWrap: {
    display: 'inline-block',
    width: '50px !important',
    height: '50px !important',
  },
  cardIngText: {
    fontFamily: FONTS.Graphik.GraphikLight + ' !important',
    display: 'inline-block',
    fontSize: '0.75rem !important',
    lineHeight: '1.67',
    padding: '17px 0 0 11px',
    verticalAlign: 'top',
  },
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    background: 'rgba(255,255,255,0.5)',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
  },
  errImage: {
    objectFit: 'none',
    width: '80px !important',
  },
  errIgImage: {
    width: '50px !important',
    height: '50px !important',
  },
  boxInfo: {
    display: 'block',
    width: '100%',
  },
  cardBrandName: {
    fontFamily: FONTS.Graphik.GraphikLight + ' !important',
    fontSize: '10px !important',
    textTransform: 'lowercase',
    color: theme.palette.common.black,
    letterSpacing: '1px',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      fontSize: '12px !important',
      float: 'none',
      display: 'block',
    },
  },
  boxPrices: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  saleBox: {
    display: 'flex',
    flexDirection: 'column',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      display: 'block',
    },
  },
  cardName: {
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    fontFamily: FONTS.Graphik.GraphikRegular + ' !important',
    fontSize: '12px !important',
    textTransform: 'lowercase',
    color: theme.palette.common.black,
    letterSpacing: '1px',
    fontWeight: 600,
  },
  cardPrice: {
    fontFamily: FONTS.Graphik.GraphikRegular + ' !important',
    fontSize: '12px !important',
    textTransform: 'lowercase',
    color: theme.palette.common.black,
    letterSpacing: '1px',
    display: 'inline-block',
    float: 'left',
    padding: '0 5px 0 0',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      padding: 0,
      display: 'block',
      float: 'none',
      textAlign: 'right',
    },
  },
  cashPercentage: {
    fontFamily: FONTS.Graphik.GraphikRegular + ' !important',
    fontSize: '12px !important',
    textTransform: 'lowercase',
    color: '#175972',
    letterSpacing: '1px',
    display: 'inline-block',
    float: 'right',
    padding: '0 0 0 5px',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      float: 'none',
      display: 'block',
      padding: 0,
    },
  },
  createLinkBtns: {
    width: '100%',
    maxWidth: 706,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  boardBtn: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    height: 44,
    alignItems: 'center',
    paddingLeft: '8px',
    paddingRight: '8px',
    cursor: 'pointer',
    borderColor: theme.palette.common.black,
  },
  socialText: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    display: 'block',
    margin: '0px auto',
    fontSize: '0.75rem',
    color: theme.palette.common.black,
  },
  inputContainer: {
    width: '100%',
    maxWidth: 706,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  socialButton: {
    ['& > a, button']: {
      width: '100%',
      maxWidth: 706,
      border: `2px solid ${theme.palette.common.black} !important`,
      height: 44,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
      padding: '0px 8px !important',
      textDecoration: 'none',
      backgroundColor: theme.palette.common.white,
    },
  },
  socialIcon: {
    display: 'block',
    width: '100%',
    maxWidth: 24,
  },
  smsButton: {
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    cursor: 'pointer',
    color: COLORS.darkGrey2,
  },
  shopLinkBtn: {
    color: theme.palette.common.black,
    fontSize: '13px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    backgroundColor: COLORS.lightTeal,
    margin: '0px auto',
    textDecoration: 'none',
    cursor: 'pointer',
    width: '100%',
    maxWidth: 706,
    border: `2px solid ${theme.palette.common.black} !important`,
    height: 44,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  convertedLink: {
    position: 'relative',
    ['& > input']: {
      color: theme.palette.common.black,
      letterSpacing: 1.2,
      textAlign: 'left',
      background: 'white',
      border: `1px solid ${COLORS.lightGrey}`,
    },
    ['& > button']: {
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100px',
      margin: 0,
    },
  },
  convertAnotherLinkBtn: {
    fontSize: '16px',
    fontFamily: FONTS.Graphik.GraphikLight,
    background: 'transparent',
    border: 'none',
    display: 'block',
    margin: '0px auto',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  copyBtn: {
    backgroundColor: `${COLORS.lightTeal} !important`,
    border: `2px solid ${theme.palette.common.black} !important`,
  },
  copyTextBtn: {
    color: '#000 !important',
  },
}))
