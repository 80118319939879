import { makeStyles } from '@material-ui/core/styles'
import { FONTS, BREAKPOINTS, COLORS } from '../../../../constants'

export const styles = makeStyles(theme => ({
  title: {
    fontSize: '32px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    margin: 0,
  },
  text: {
    fontSize: '16px',
    margin: '20px 0 0',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontStyle: 'normal',
    lineHeight: '21px',
    letterSpacing: '0.156px',
    textTransform: 'lowercase',

    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: '300px',
    },
  },
  loginButton: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: COLORS.black,
  },
  dialogTitle: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    color: theme.palette.common.black,
  },
  toolbar: {
    position: 'relative',
    padding: '20px 15px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  homeHeaderLogo: {
    width: '136px',
    height: '35px',
  },
  logo: {
    width: '26px',
    height: '28px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0 40px',
    justifyContent: 'center',
    height: 'calc(100vh - 64px)',
  },
  menuIcon: {
    width: '24px',
    height: '11.2px',
  },
}))
