import React from 'react'
import chirpyestTextLogo from '../../../assets/images/chirpyest-text-logo.svg'
import chrLogo from '../../../assets/images/chirpyest.svg'

import { styles } from './styles'

interface WelcomeAppProps {
  setIsNew: Function
}

const WelcomeApp = ({ setIsNew }: WelcomeAppProps) => {
  const classes = styles()
  return (
    <div className={classes.loadingScreenWrapper}>
      <img src={chrLogo} className={classes.logo} alt="Chirpyest Logo" />
      <div>
        <img
          src={chirpyestTextLogo}
          alt="Chirpyest"
          className={classes.textLogo}
        />
        <p className={classes.text}>shop. share. earn cash back.</p>
      </div>
      <div className={classes.description}>
        <p className={classes.text}>
          enable the chirpyest extension for safari, shop at connected sites and
          we’ll find cash back you!
        </p>
      </div>
      <a
        href={`${process.env.GATSBY_SITE_URL}/welcome`}
        target="_blank"
        className={classes.welcomeLink}
        onClick={() => setIsNew(false)}
      >
        enable safari extension
      </a>
    </div>
  )
}

export default WelcomeApp
