import React, { useCallback, useEffect, useState } from 'react'
import burgerMenuIcon from '../../../assets/images/burger-menu.svg'
import Dialog from '@material-ui/core/Dialog'
import { styles } from './styles'
import chrLogo from '../../../assets/images/chirpyest.svg'
import chirpyestTextLogo from '../../../assets/images/chirpyest-text-logo.svg'
import { Box, DialogTitle, IconButton, Typography } from '@material-ui/core'
import CHRHeaderDialog from '../../headerDialog'
import SignInButton from '../signInButton'
import { COLORS, ENDPOINTS, baseURL } from '../../../constants'
import CHRSpinner from '../../spinner'

import {
  generateTrackingLink,
  getBrandsLogosURLSrc,
} from '../../../../utils/brandsHelper'
import { trendingBrandsLogos } from '../../../../utils/trendingBrandsLogos'
import { useAppState } from '../../../appState'
import axios from '../../../axios'

const OpenApp = () => {
  const classes = styles()
  const [isOverlayOpen, setOverlayStatus] = useState(false)
  const [topBrands, setTopBrands] = useState([])
  // @ts-ignore
  const [appState] = useAppState()

  const urlParams = new URLSearchParams(window.location.search)
  const fromLogin = urlParams.get('fromLogin')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}${ENDPOINTS.brandsList}?website=1`
        )
        console.log('response', response)
        const data = response.data.data

        const filteredBrands = data.filter((item: any) => item.isTrending)
        const filteredTrendingBrands = getBrandsLogosURLSrc(
          filteredBrands,
          trendingBrandsLogos
        )
        setTopBrands(filteredTrendingBrands)
      } catch (error) {
        console.error('An error occurred while fetching data:', error)
      }
    }

    fetchData()
  }, [])

  if (!topBrands.length) return <CHRSpinner />

  const openMenuOverlay = useCallback(() => {
    setOverlayStatus(true)
  }, [])

  const closeMenuOverlay = useCallback(() => {
    setOverlayStatus(false)
  }, [])

  const openChirpyestApplication = () => {
    window.location.href = `ChirpyestApp://open?username=${appState.username}&userId=${appState.userId}`
  }

  const buttonProps = [
    {
      onClick: openChirpyestApplication,
      label: 'open the app',
      customButtonStyle: {
        backgroundColor: COLORS.lightTeal,
        marginTop: '20px',
        marginBottom: 0,
        width: '200px',
      },
      id: 1,
    },
  ]

  return (
    <Dialog
      fullScreen={true}
      open={true}
      aria-labelledby="responsive-dialog-title"
    >
      {/* Dialog Header */}
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={openMenuOverlay}
          >
            <img
              src={burgerMenuIcon}
              className={classes.menuIcon}
              alt={'menu'}
            />
          </IconButton>

          <img
            src={chirpyestTextLogo}
            className={classes.homeHeaderLogo}
            alt="chirpyest-text-logo"
          />
          <img src={chrLogo} className={classes.logo} />
        </Box>
      </DialogTitle>
      <Box className={classes.dialogContainer}>
        <Box component="section" className={classes.dialogContent} mb={7.5}>
          {/* Title & Description */}
          <Typography variant="h2" className={classes.title}>
            {fromLogin
              ? 'congratulations, you’re all set!'
              : 'You are signed in!'}
          </Typography>

          <Typography variant="subtitle1" className={classes.text}>
            start shopping + sharing from connected sites
            <Typography variant="subtitle1" className={classes.text}>
              on safari and our extension will find cash back you!{' '}
            </Typography>
          </Typography>

          {buttonProps.map((button: any) => (
            <SignInButton
              key={button.id}
              onClick={button.onClick}
              label={button.label}
              customButtonStyle={button.customButtonStyle}
            />
          ))}
        </Box>
        <Box className={classes.topBrandsContainer}>
          <h3 className={classes.topBrandsTitle}>start shopping</h3>
          {topBrands.slice(3).map((brand: any) => {
            const { brandName, commission } = brand
            return (
              <Box className={classes.shopItem} key={brandName}>
                <Box className={classes.brandCard}>
                  <img src={brand?.src} className={classes.topBrandImage} />
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <p className={classes.brandName}>{brandName}</p>
                    <p className={classes.cashBack}>
                      <strong>{commission}</strong> cash back
                    </p>
                  </Box>
                </Box>

                <a
                  href={generateTrackingLink(
                    brand,
                    {
                      userId: appState.userId,
                      userName: appState.username,
                    },
                    '',
                    '',
                    Boolean(appState.userId)
                  )}
                  className={classes.shopLink}
                  target="_blank"
                >
                  shop
                </a>
              </Box>
            )
          })}
        </Box>
      </Box>
      <CHRHeaderDialog
        handleClose={closeMenuOverlay}
        openStatus={isOverlayOpen}
        isFromIos
      />
    </Dialog>
  )
}

export default OpenApp
